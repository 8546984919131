import moment from 'moment';
import { lotSizeAndStrikeIntervalConfig } from 'zeequant-constants';

export const instrumentLotSizeAndStrikeIntervalConfigs = (symbol, expiryDateMoment) => {
  const sortedDates = Object.keys(lotSizeAndStrikeIntervalConfig)
    .filter((date) => moment(date).isSameOrBefore(expiryDateMoment, 'day'))
    .sort()
    .reverse();

  const instrumentConfig = sortedDates
    .map((date) => lotSizeAndStrikeIntervalConfig[date])
    .flatMap((contracts) => contracts.filter((contract) => contract.name === symbol))
    .find(Boolean);

  return instrumentConfig || null;
};
