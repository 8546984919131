import React from 'react';
import PropTypes from 'prop-types';

import Money from 'common/components/MoneyDisplay';
import TransactionType from 'common/components/TransactionType';

import { STATUS_STYLE_CONFIG } from 'modules/OrderDetails/config';
import { ORDER_TYPES } from 'common/constants/index';
import HoverMessage from 'common/components/HoverMessage';
import moment from 'moment';
import TradingSymbol from 'common/components/TradingSymbol';
import LinkToIOStrategy from 'v2/common/components/LinkToIOStrategy/index';
import LinkToIOOptionChain from 'v2/common/components/LinkToIOOptionChain/index';
import TransactionMoreDetails from './TransactionMoreDetails';

const propTypes = {
  cumulativeProfit: PropTypes.number,
  isLive: PropTypes.bool.isRequired,
  onHandleModalInParent: PropTypes.func.isRequired,
  orderType: PropTypes.string.isRequired,
  granularity: PropTypes.string.isRequired,
  runStatus: PropTypes.string.isRequired,
};

const defaultProps = { cumulativeProfit: null, runId: null, shouldHideViewButton: false };

const TransactionExpanded = ({
  transaction, cumulativeProfit, isLive, onHandleModalInParent, orderType, granularity, runStatus
}) => {
  if (_.isEmpty(transaction)) return null;
  const isShowStatus = orderType !== ORDER_TYPES.paper.value && isLive;

  const {
    transactionNumber, isComplete, pairedOrders, groupedByTradingSymbolSize
  } = transaction;

  let rowSpanCount = groupedByTradingSymbolSize;
  const resetRowSpanCount = () => {
    rowSpanCount = null;
  };
  const renderTransactionId = (
    <td className="align-middle">
      <div className="transaction-number">{transactionNumber}</div>
    </td>
  );

  const renderCumulativeProfit = () => {
    return (
      <>
        <td className="align-middle text-success text-right" rowSpan={rowSpanCount}>
          {!isComplete && <div className="incomplete" />}
          {isComplete && <Money shouldColor>{cumulativeProfit}</Money>}
        </td>
        <td className="align-middle" rowSpan={rowSpanCount}>
          <LinkToIOStrategy pairedOrders={pairedOrders} additionalClassNames="ignore-expand-collapse" />
        </td>
      </>
    );
  };

  const renderEntryAndExitFormatters = (
    price, time, timeISO, type, statusClassName, status, statusText, failureMessage, isWaiting, tradingSymbol
  ) => {
    const altertedTime = (!isLive && granularity === 'minute') ? moment(time, 'DD MMM YYYY HH:mm:ss')
      .format('DD MMM YYYY HH:mm') : time;
    const alteredStatusText = isWaiting && status === 'confirmed' ? 'waiting' : statusText;

    return (
      <div>
        <div className="entry">
          {_.isNumber(price) ? <div className="price"><Money>{price}</Money></div> : <div>-</div>}
          <div className="time">
            {time ? altertedTime : '-'}
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <TransactionType type={type} />
          <div className="d-flex align-items-center">
            {(isShowStatus && status) && (
              <span className={`${statusClassName} status`}>{alteredStatusText}</span>
            )}
            {failureMessage
              && (
                <HoverMessage title="Message" message={failureMessage}>
                  <span className="material-icons-outlined text-dark tx-12 align-middle ml-1">info</span>
                </HoverMessage>
              )}
          </div>
        </div>
        <LinkToIOOptionChain
          tradingSymbol={tradingSymbol}
          time={timeISO}
          additionalClassNames="ignore-expand-collapse"
        />
      </div>
    );
  };

  const renderOrders = (ordersPair, key) => {
    const {
      entryType, exitType, entryTime, exitTime, orderProfit, entryPrice,
      exitPrice, quantity, hasIlliquid, tradingSymbol, entryOrderStatus, exitOrderStatus,
      entryOrder, exitOrder, entryTimeISO, exitTimeISO
    } = ordersPair;

    const entryFailureMessage = _.get(entryOrder, 'failure_message', null);
    const exitFailureMessage = _.get(exitOrder, 'failure_message', null);

    const entryStatusConfig = _.get(STATUS_STYLE_CONFIG, entryOrderStatus);
    const entryStatusText = _.get(entryStatusConfig, 'statusText', entryOrderStatus);
    const entryStatusTextClassName = _.get(entryStatusConfig, 'statusTextClassName', 'text-muted');
    const isEntryWaiting = _.get(entryOrder, 'is_waiting', false);

    const exitStatusConfig = _.get(STATUS_STYLE_CONFIG, exitOrderStatus);
    const exitStatusText = _.get(exitStatusConfig, 'statusText', exitOrderStatus);
    const exitStatusTextClassName = _.get(exitStatusConfig, 'statusTextClassName', 'text-muted');
    const isExitWaiting = _.get(exitOrder, 'is_waiting', false);

    return (
      <tr className="expanded" key={key}>
        {rowSpanCount ? renderTransactionId : <td className="border-top-0" />}
        <td><TradingSymbol tradingSymbol={tradingSymbol} /></td>
        <td>
          <div className="qty-no">
            {quantity}
            <div className="tx-10 text-muted">Qty</div>
          </div>
        </td>
        <td colSpan="3" className="entry-wrapper">
          {renderEntryAndExitFormatters(
            entryPrice, entryTime, entryTimeISO, entryType, entryStatusTextClassName, entryOrderStatus,
            entryStatusText, entryFailureMessage, isEntryWaiting, tradingSymbol
          )}
        </td>

        <td colSpan="3" className="exit-wrapper">
          {renderEntryAndExitFormatters(
            exitPrice, exitTime, exitTimeISO, exitType, exitStatusTextClassName, exitOrderStatus,
            exitStatusText, exitFailureMessage, isExitWaiting, tradingSymbol
          )}
        </td>

        <td className="text-right">
          {hasIlliquid && <div className="illiquid" />}
          <Money shouldColor>{orderProfit}</Money>
          <TransactionMoreDetails
            orderType={orderType}
            isLive={isLive}
            runStatus={runStatus}
            ordersPair={ordersPair}
            key={key}
            onHandleModalInParent={onHandleModalInParent}
          />
        </td>
        {!!rowSpanCount && renderCumulativeProfit()}
        {resetRowSpanCount()}
      </tr>
    );
  };

  return _.map(pairedOrders, renderOrders);
};

TransactionExpanded.propTypes = propTypes;
TransactionExpanded.defaultProps = defaultProps;
export default TransactionExpanded;
