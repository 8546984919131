import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MoneyDisplay from 'common/components/MoneyDisplay';
import TransactionType from 'common/components/TransactionType';
import { STATUS_STYLE_CONFIG } from 'modules/OrderDetails/config';
import TransactionOrderDetails from
  'modules/TransactionTable/components/TransactionsList/TransactionOrderDetails';
import classNames from 'classnames';
import { ORDER_TYPES } from 'common/constants/index';
import HoverMessage from 'common/components/HoverMessage';
import TradingSymbol from 'common/components/TradingSymbol';
import LinkToIOOptionChain from 'v2/common/components/LinkToIOOptionChain/index';

const propTypes = {
  pairedOrder: PropTypes.shape({
    entryOrder: PropTypes.shape({}),
    exitOrder: PropTypes.shape({}),
    tradingSymbol: PropTypes.string,
    quantity: PropTypes.number,
    orderProfit: PropTypes.number,
    entryPrice: PropTypes.number,
    exitPrice: PropTypes.number,
    entryOrderStatus: PropTypes.string,
    exitOrderStatus: PropTypes.string,
    entryTime: PropTypes.string,
    exitTime: PropTypes.string,
    entryTimeISO: PropTypes.string,
    exitTimeISO: PropTypes.string
  }).isRequired,
  orderType: PropTypes.string.isRequired,
  runStatus: PropTypes.string.isRequired
};
const defaultProps = {};

const LiveOrderDetails = ({ pairedOrder, orderType, runStatus }) => {
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const {
    entryOrder, exitOrder, tradingSymbol, quantity, orderProfit, entryPrice, exitPrice,
    entryOrderStatus, exitOrderStatus, entryTime, exitTime, entryTimeISO, exitTimeISO
  } = pairedOrder;
  const isActual = orderType !== ORDER_TYPES.paper.value;

  const renderOrder = (order, price, time, status, timeISO) => {
    if (_.isEmpty(order)) return null;
    const {
      is_entry: isEntry, transaction_type: transactionType,
      failure_message: failureMessage, is_waiting: isWaiting
    } = order;

    const statusConfig = _.get(STATUS_STYLE_CONFIG, status);
    const statusText = _.get(statusConfig, 'statusText', status);
    const statusTextClassName = _.get(statusConfig, 'statusTextClassName', 'text-muted');
    const alteredStatusText = isWaiting && status === 'confirmed' ? 'waiting' : statusText;

    const flexClassName = classNames('d-flex align-items-center gap-5 flex-wrap', {
      'justify-content-end': !isEntry
    });

    return (
      <>
        <div className="text-muted mb-1">{isEntry ? 'Entry' : 'Exit'}</div>
        <div className={flexClassName}>
          <TransactionType type={transactionType} />
          <span className="spacing" />
          {price
            ? <div className="font-weight-medium"><MoneyDisplay>{price}</MoneyDisplay></div> : ''}
          {price ? <span className="spacing" /> : ''}
          <div className={isEntry ? 'time tx-10' : 'time text-right tx-10'}>{time || ''}</div>
          <LinkToIOOptionChain tradingSymbol={tradingSymbol} time={timeISO} />
        </div>
        <div className="d-flex align-items-center">
          {(status && isActual) && (
            <span className={`${statusTextClassName} status mt-1`}>
              {alteredStatusText}
            </span>
          )}
          {failureMessage && (
            <HoverMessage title="Message" message={failureMessage}>
              <span className="material-icons-outlined text-dark tx-12 align-middle ml-1">info</span>
            </HoverMessage>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="card border-0  border-bottom">
      <div className="card-head d-flex p-0 mb-2">
        <div className="text-truncate">
          <div className="trading-symbol">
            <TradingSymbol tradingSymbol={tradingSymbol} />
          </div>
          <div className="sub-information gap-5">
            <div>
              <span className="text-muted">Qty : </span>
              <span className="font-weight-semi">{quantity}</span>
            </div>

            <span className="spacing" />

            <div className="d-flex align-items-center gap-5">
              <span className="text-muted">P/L : </span>
              <span className="font-weight-medium d-flex align-items-center">
                {orderProfit ? <MoneyDisplay shouldColor>{orderProfit}</MoneyDisplay> : '-'}
                {/* <i className="material-icons-outlined icons txt-12">arrow_drop_up</i> */}
              </span>
            </div>
          </div>
        </div>
        <button
          className="btn btn-sm btn-primary"
          type="button"
          onClick={() => setShouldShowModal(!shouldShowModal)}
        >
          More
        </button>
      </div>
      <div className="card-body px-0 d-flex justify-content-between">
        <div className="enter-side custom-mr-0 flex-column align-items-start">
          {renderOrder(entryOrder, entryPrice, entryTime, entryOrderStatus, entryTimeISO)}
        </div>
        <div className="exit-side custom-mr-0 flex-column align-items-end">
          {renderOrder(exitOrder, exitPrice, exitTime, exitOrderStatus, exitTimeISO)}
        </div>
      </div>
      <TransactionOrderDetails
        shouldShowModal={shouldShowModal}
        onHandleModal={() => setShouldShowModal(!shouldShowModal)}
        ordersPair={pairedOrder}
        orderType={orderType}
        runStatus={runStatus}
      />
    </div>
  );
};

LiveOrderDetails.propTypes = propTypes;
LiveOrderDetails.defaultProps = defaultProps;

export default LiveOrderDetails;
