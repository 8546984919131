/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { SEGMENT_CONFIG } from 'common/constants/index';
import { pairedOrdersPropTypes } from 'common/propTypes';
import { parseTradingSymbol } from 'v2/common/utils/tradingSymbol';
import { INSTAOPTIONS_MODE } from 'v2/common/constants/index';
import HoverMessage from 'common/components/HoverMessage';
import { instrumentLotSizeAndStrikeIntervalConfigs } from 'v2/common/utils/zeequantConstants';
import LinkToIO from '../LinkToIO/index';

const propTypes = {
  pairedOrders: PropTypes.arrayOf(pairedOrdersPropTypes).isRequired,
  additionalClassNames: PropTypes.string,
};
const defaultProps = {
  additionalClassNames: '',
};

const today = moment();
const LinkToIOStrategy = ({ pairedOrders, additionalClassNames }) => {
  if (!pairedOrders) {
    return null;
  }

  const legConfigs = _.chain(pairedOrders)
    .map((pairedOrder) => {
      const {
        tradingSymbol,
        entryOrder,
        entryTimeISO,
        entryType,
        entryPrice,
        exitTimeISO,
        exitPrice,
      } = pairedOrder;
      const {
        instrument, expiryDate, strikePrice, segment, symbolType
      } = parseTradingSymbol(tradingSymbol);

      if (segment !== SEGMENT_CONFIG.option) {
        return null;
      }

      const { quantity } = entryOrder;
      const { lot_size: lotSize } = instrumentLotSizeAndStrikeIntervalConfigs(instrument, expiryDate) || {};
      const lots = quantity / (lotSize || 1);

      return {
        instrument,
        transactionType: entryType,
        optionType: symbolType,
        strikePrice,
        strikeIndex: 1,
        strikeLabel: 'ATM-5',
        lot: lots,
        price: entryPrice,
        legExpiry: moment(expiryDate, 'DDMMMYY').format('YYYY-MM-DD'),
        ltpDisplayFormat: entryPrice,
        datetime: entryTimeISO,
        exitPrice,
        exitDateTime: exitTimeISO,
      };
    })
    .compact()
    .value();

  if (legConfigs.length <= 0) {
    return null;
  }

  const { instrument } = legConfigs[0];

  // TODO: If multiple symbols, then showing only link to first symbol currently.
  // Show multiple.
  const legs = _.chain(legConfigs)
    .filter((legConfig) => legConfig.instrument === instrument)
    .map((legConfig) => _.omit(legConfig, 'instrument'))
    .value();
  const dateTime = moment(
    _.chain(pairedOrders)
      .map((l) => l.exitTimeISO || l.entryTimeISO)
      .sort()
      .last()
      .value()
  );

  const isLive = dateTime.isSame(today, 'day');
  const ioHistoricalTime = moment(dateTime).format('YYYY-MM-DD 15:29');
  const queryParams = {
    instrument,
    expiryDate: legConfigs[0].legExpiry,
    userRunType: isLive ? INSTAOPTIONS_MODE.live : INSTAOPTIONS_MODE.historical,
    historicalDateAndTime: isLive ? null : ioHistoricalTime,
    currentTab: 'combined_premium',
    currentStrategy: 'shortStrangle',
    legs,
  };

  return (
    <LinkToIO
      page="basket"
      queryParams={queryParams}
      additionalClassNames={additionalClassNames}
    >
      <HoverMessage title="Premium Chart" message="View this transaction in InstaOptions Strategy Builder">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#295F98"
        >
          <path d="M120-120v-80l80-80v160h-80Zm160
          0v-240l80-80v320h-80Zm160 0v-320l80 81v239h-80Zm160
          0v-239l80-80v319h-80Zm160 0v-400l80-80v480h-80ZM120-327v-113l280-280
          160 160 280-280v113L560-447 400-607 120-327Z"
          />
        </svg>
      </HoverMessage>
    </LinkToIO>
  );
};

LinkToIOStrategy.propTypes = propTypes;
LinkToIOStrategy.defaultProps = defaultProps;
export default LinkToIOStrategy;
